import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const IndustryCard = ({ title, image }) => (
  <div className="industry-card">
    <Link to={`/our-work/#${title}`} className={`industry-card-${title.split(' ')[0]}`} tabIndex="-1">
      <Img className="industry-card__image" fluid={image} alt={title} title={title} width="167" height="202" />
      <div className="industry-card__title-container">
        <h2
          className="industry-card__title"
          tabIndex="0"
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              document.querySelector(`.industry-card-${title.split(' ')[0]}`).click();
            }
          }}
        >
          {title}
        </h2>
        <div className="job__details--icon icon fa-arrow-right" />
      </div>
    </Link>
  </div>
);

IndustryCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default IndustryCard;
