import React from 'react';
import Slider from 'react-slick/lib';
import classnames from 'classnames';

import Icons from './Icons';
import BlankA from './BlankHyperlink';
import { NextArrow, PrevArrow } from './Arrows';

export const trustedByCompaniesIcons = [{
  icon: 'renaissance'
}, {
  icon: 'cbre-min'
}, {
  icon: 'siemens'
}, {
  icon: 'bmw'
}, {
  icon: 'acer'
}, {
  icon: 'massmutual'
}, {
  icon: 'teachforward'
}, {
  icon: 'illuminateed'
}, {
  icon: 'gsk'
}, {
  icon: 'ey-min'
}, {
  icon: 'pizza-hut-min'
}, {
  icon: 'burger-king-min'
}, {
  icon: 'tilster-min'
}, {
  icon: 'mari-min'
}, {
  icon: 'smashcut-min'
}, {
  icon: 'sigora-min'
}, {
  icon: 'demco-min'
}, {
  icon: 'dstoq-min'
}, {
  icon: 'conference-compass-min'
}, {
  icon: 'apothera'
}, {
  icon: 'docviser'
}, {
  icon: 'livebuy'
}, {
  icon: 'parking-match'
}, {
  icon: 'stitch'
}];

const zoomSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,

  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ],
};

const HomeCompaniesIcons = () => {
  const iconsItems = [];
  trustedByCompaniesIcons.map(({ href, icon }) => {
    if (href) {
      return iconsItems.push(
        <BlankA key={icon} href={href} className={classnames('icon-layout', icon)}>
          <div className="label">{icon}</div>
        </BlankA>
      );
    }
    return iconsItems.push(<Icons key={icon} icon={icon} />);
  });

  return (
    <div className="icons-container-hp">
      <Slider {...zoomSettings} initialSlide={0}>
        {Array.isArray(trustedByCompaniesIcons) && trustedByCompaniesIcons.map(({ icon }, imageIdx) => (
          <div className={classnames('slider-icon', icon, 'icon-layout')} key={`icon_${(imageIdx + 1)}`} />
        ))}
      </Slider>
    </div>
  );
};

export default HomeCompaniesIcons;
