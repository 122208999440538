import React, { Component } from 'react';
import HubspotForm from 'react-hubspot-form';
import hubSpotConfig from '../config/hubspotConfig';

const { portalId, formId } = hubSpotConfig;

class NewQuoteForm extends Component {
  componentDidMount() {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
        this.setCookie('hubspotutk', '', -1);
      }
    });
  }

  setCookie = (name, value, days) => {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name} = ${value} ;path=/;expires= ${d.toGMTString()}`;
  };

  render() {
    return (
      <div id="quote-form">
        <HubspotForm
          portalId={portalId}
          formId={formId}
          onFormSubmit={() => console.log('submit')}
          className="robots-nocontent"
        />
      </div>
    );
  }
}

export default NewQuoteForm;
