import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { push } from 'gatsby-link';
import { Element } from 'react-scroll';
import Slider from 'react-slick/lib';
import Img from 'gatsby-image';
import classnames from 'classnames';

import Layout from '../components/GatsbyLayout';
import Subtitle from '../components/Subsection/Subtitle';
import HomeCompaniesIcons, { trustedByCompaniesIcons } from '../components/HomeCompaniesIcons';
import Button from '../components/Button';
import Helmet from '../components/Helmet';
import Article from '../components/Article';

import ProjectCard from '../components/ProjectCard';
import Testimonial from '../components/Testimonial';
import { NextArrow, PrevArrow } from '../components/Arrows';
import IndustryCard from '../components/IndustryCard';
import { WhatWeAreGoodAt, OurWork, Testimonials, Industries } from '../data/home-data';
import ContactForm from '../components/ContactForm';

const removeDuplicates = (duplicates) => {
  const flag = {};
  const unique = [];
  duplicates.forEach((elem) => {
    if (!flag[elem.quote]) {
      flag[elem.quote] = true;
      unique.push(elem);
    }
  });
  return unique;
};
const uniqueTestimonials = removeDuplicates(Testimonials);

export default class HomeIndex extends React.Component {
  static getFeaturedArticles(edges) {
    return edges
      .filter((edge) => {
        const { showOnHome } = edge.node.frontmatter;
        return showOnHome && !!edge.node.frontmatter.date;
      })
      .map(edge => (
        <Article
          key={edge.node.id}
          title={edge.node.frontmatter.title}
          readingTime={edge.node.timeToRead}
          date={edge.node.frontmatter.date}
          path={edge.node.frontmatter.path}
          imageFluid={edge.node.frontmatter.mainImage.childImageSharp.fluid}
          imageFluidMobile={edge.node.frontmatter.mobileImage
            && edge.node.frontmatter.mobileImage.childImageSharp.fluid}
        />
      ))
      .slice(0, 3);
  }

  constructor(props) {
    super(props);

    const { data: { allMarkdownRemark: { edges } } } = props;

    const posts = HomeIndex.getFeaturedArticles(edges);

    this.state = {
      slideIndex: 1,
      posts,
      width: 100
    };

    this.renderHelmet = this.renderHelmet.bind(this);
    this.renderBlog = this.renderBlog.bind(this);
  }

  componentDidMount() {
    if (window.location.search === '?calendly=true') {
      push('/contact/');
      setTimeout(() => {
        window.location = 'https://calendly.com/alex-lazar/mcro-in-30min/08-16-2018';
      }, 1000);
    }

    document.querySelectorAll('.slick-arrow img').forEach((arrow) => {
      arrow.setAttribute('tabIndex', '0');
      arrow.onkeypress = () => { arrow.click(); }; // eslint-disable-line no-param-reassign
    });

    document.querySelectorAll('.slick-active > div').forEach((icon) => {
      icon.setAttribute('tabIndex', '0');
    });
    document.querySelectorAll('.testimonials .slick-active').forEach((icon) => {
      icon.setAttribute('tabIndex', '0');
    });

    document.querySelectorAll('.testimonials .slick-active .testimonial').forEach((icon) => {
      icon.setAttribute('tabIndex', '0');
    });

    const newWidth = typeof window !== 'undefined' && window.innerWidth;
    this.setState({
      width: newWidth
    });
  }

  renderImage = () => {
    const { data } = this.props;

    return <img src={data.homeImage.childImageSharp.fluid.src} className="home__image" alt="MCRO" title="MCRO" />;
  }

  renderTabletImage = () => {
    const { data } = this.props;

    return (
      <img
        src={data.homeImageTablet.childImageSharp.fluid.src}
        className="home__image-tablet"
        alt="MCRO"
        title="MCRO"
      />
    );
  }

  renderHelmet = () => {
    const { data } = this.props;

    const siteTitle = data.site.siteMetadata.title;
    const siteDescription = data.site.siteMetadata.description;
    const siteKeywords = `Product Design, User Experience & Interface Design, Web Development, Javascript, React,
      React Native, Angular, NodeJS, iOS, Android, Swift, Product Development, QA, Automation, DevOPS, PHP, Python, 
      Amazon Web Services, Google Cloud, Top World-Class Engineers, Managing Experts and Consultants.`;

    return (
      <Helmet
        title={siteTitle}
        description={siteDescription}
        keywords={siteKeywords}
        canonical
      />
    );
  };

  // this section is not like the other sections in the app
  // so we won't use main__section class for now
  renderMainSection = () => (
    <div className="main home-main">
      <div className="home-main__text-content">
        <Subtitle
          title="Custom software development solutions for your business"
          titleType="withDot"
          withoutLine
        />
        <p className="case-study-banner--section__text" tabIndex="0">
          Take your business to the next level through cutting-edge digital solutions of the future.
        </p>
        <div className="home__buttons">
          <Button
            type="primary-large-light-link"
            goTo="/contact/"
            title="Contact us"
            classNames="home__button"
          />
          <Button
            type="secondary-large"
            goTo="/our-work/"
            title="View Case Studies"
            classNames="home__button-no-border"
          />
          <div className="job__details--icon icon fa-arrow-right" />
        </div>
      </div>
    </div>
  );

  renderSubtitle = ({ title, description, button, classNames }) => (
    <Subtitle
      title={title}
      afterTitle={description}
      titleType="withDot"
      withoutLine
      classNames={classNames}
      specialLayout={children => (
        <div className="home__subsection--button-layout">
          {children}
          {button}
        </div>
      )}
    />
  );

  renderSectionButton = (title, linkTo, className) => (
    <Button
      internalLink
      type="primary-large-light"
      goTo={linkTo}
      title={title}
      classNames={`home__subsection__button ${className || ''}`}
    />
  );

  renderWhatWeAreGoodAt = () => (
    <Element name="myScrollToElement" className="home__subsection home__section-first">
      {this.renderSubtitle({
        title: 'What we\'re good at',
        description: 'We provide end-to-end services and solutions for every stage of your digital transformation.',
      })}
      <div className="what-we-are-good-at cards">
        { WhatWeAreGoodAt.map(elem => (
          <ProjectCard
            key={elem.title}
            image={elem.image}
            title={elem.title}
            content={elem.description}
            btnName="Find out more"
            toServicesPage={elem.link}
          />
        )) }
      </div>
    </Element>
  );

  renderIndustries = () => {
    const { data } = this.props;
    const images = [data.industry1, data.industry2, data.industry3, data.industry4, data.industry5, data.industry6,
      data.industry7];
    return (
      <div className="home__subsection industries__section">
        <div className="industries">
          {this.renderSubtitle({
            title: 'Industries we have worked with',
            description: 'Here are some of the industries we have made an impact on over the course of the years.'
          })}
          <div className="industry-cards">
            {Industries.map((element, index) => (
              <IndustryCard
                key={element.title}
                image={images[index].childImageSharp.fluid}
                title={element.title}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }


  renderTestimonials = () => {
    const settings = {
      infinite: true,
      centerPadding: '50px',
      slidesToShow: 1,
      speed: 500,
      adaptiveHeight: true,
      dots: false,
      beforeChange: (current, next) => this.setState({ slideIndex: next + 1 }),
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      lazyLoad: 'progressive',

      responsive: [
        {
          breakpoint: 480,
          settings: {
            touchMove: true,
            beforeChange: (current, next) => this.setState({ slideIndex: next + 1 }),
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            centerPadding: '74px',
            verticalSwiping: false,
            horizontalSwiping: true,
            touchThreshold: 10,
          }
        }
      ]
    };
    const { slideIndex } = this.state;

    const clickPrev = () => {
      document.querySelector('.testimonials .slick-prev').click();
    };
    const clickNext = () => {
      document.querySelector('.testimonials .slick-next').click();
    };

    return (
      <div className="home__subsection">
        <div className="testimonials main main__inner">
          <div className="testimonials-slideshow">
            {this.renderSubtitle({
              title: 'What our clients say about us',
            })}
            <Slider {...settings}>
              {uniqueTestimonials.map(testimonial => (
                <Testimonial
                  key={testimonial.quote}
                  quote={`"${testimonial.quote}"`}
                  imgSrc={testimonial.imageSrc}
                  position={testimonial.position}
                  author={testimonial.author}
                />
              ))}
            </Slider>
          </div>
          <div className="slider-section">
            <PrevArrow onClick={clickPrev} className="slider-arrow" />
            <div className="slider-layout--index">
              {slideIndex}
              /
              {uniqueTestimonials.length}
            </div>
            <NextArrow onClick={clickNext} className="slider-arrow" />
          </div>
        </div>
      </div>
    );
  }

  renderOurWork = () => {
    const { data } = this.props;
    const images = [{ large: data.sigora, mobile: data.sigoraMobile },
      { large: data.compass, mobile: data.compassMobile }, { large: data.tillster, mobile: data.tillsterMobile }];
    return (
      <div className="home__subsection--ourwork">
        <div className="our-work">
          {this.renderSubtitle({
            title: 'Featured case studies',
            description: 'Here are some of the projects we’ve worked on.',
            classNames: 'main main__inner case-studies__subsection'
          })}
          <div className="articles main main__inner">
            {OurWork.map((caseStudy, index) => (
              <Article
                key={caseStudy.title}
                imageFluid={images[index].large.childImageSharp.fluid}
                imageFluidMobile={images[index].mobile.childImageSharp.fluid}
                description={caseStudy.description}
                path={caseStudy.link}
                title={caseStudy.title}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  renderBlogButton = className => this.renderSectionButton('View All Blog Posts', '/blog/', className);

  renderBlog = () => {
    const { posts } = this.state;

    return (
      <div className="home__subsection home__section section__blog">
        <div className="home__subsection__title-blog">
          {this.renderSubtitle({
            title: 'Featured articles',
            description: 'Head to our blog for industry insights and best practice advice.',
            button: this.renderBlogButton()
          })}
        </div>
        <div className="post__latest-items articles">{posts}</div>
        {this.renderBlogButton('mobile')}
      </div>
    );
  };

  renderGetAQuote = () => (
    <div id="contact">
      <ContactForm title="Got a great project idea?" subtitle="Let’s talk." page="home" />
    </div>
  );

  renderClientsMobile = () => (
    <div className="clients_wrapper partners-layout-mobile">
      {
        trustedByCompaniesIcons && trustedByCompaniesIcons.map((company, index) => {
          const name = company.icon;
          const url = require(`../assets/images/homePage/${name}.png`);

          return (
            <img
              src={url}
              className={'partners-layout-mobile--icon'}
              key={`icon_${index}`}
              alt="client_icon"
            />
          );
        })
      }
    </div>
  );

  renderReview = (isMobile = false) => {
    const className = `home__header-review ${isMobile ? 'home__header-review-mobile' : ''}`;

    return (
      <div className={className}>
        <div
          className="review-widget_net"
          data-uuid="77aa25c7-4fff-44df-be0f-0b07f63dde9f"
          data-template="2"
          data-filter=""
          data-lang="en"
          data-theme="light"
        >
          <a href="https://www.review-widget.net/" target="_blank" rel="noopener">
            <img
              src="https://grwapi.net/assets/spinner/spin.svg"
              title="Review Widget"
              alt="review-widget.net"
              loading="lazy"
            />
          </a>
        </div>
        <div className="home__header-review__hideElement"/>
      </div>
    );
  }

  render() {
    const { location } = this.props;
    const { width } = this.state;
    const isMobile = width <= 500;

    return (
      <Layout location={location}>
        <div className="home">
          {this.renderHelmet()}
          {this.renderImage()}
          <h1 className="show-for-seo">
            Work with our world-class engineers and managing consultants on your next idea
            and build a perfect scalable and highly performant product. We deliver the best quality!
          </h1>
          <div className="main main__inner">
            <div className="home__header-container">
              <div className="home__header">
                <div className="home__header-banner main">
                  {this.renderMainSection()}
                  {isMobile ? this.renderClientsMobile() : this.renderReview()}

                  {this.renderTabletImage()}

                  {isMobile && this.renderReview(true)}
                </div>

                {
                  !isMobile && (
                    <div className="partners-layout">
                      <div className="font-weight-normal subsection-subtitle__center-element" tabIndex="0">
                        TRUSTED BY OUR CLIENTS
                      </div>
                      <HomeCompaniesIcons />
                    </div>
                  )
                }
              </div>
            </div>

            {this.renderWhatWeAreGoodAt()}

            {this.renderOurWork()}

            {this.renderTestimonials()}

            {this.renderIndustries()}

            {this.renderBlog()}
          </div>
          {this.renderGetAQuote()}
        </div>
      </Layout>
    );
  }
}

HomeIndex.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export const pageQuery = graphql`
    query PageQuery {
      sigora: file(relativePath: { eq: "homePage/Sigora-min.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400)  {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sigoraMobile: file(relativePath: { eq: "homePage/Sigora-mobile.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 400)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      compass: file(relativePath: { eq: "homePage/Compass-min.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 400)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      compassMobile: file(relativePath: { eq: "homePage/Compass-mobile.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 400)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      tillster: file(relativePath: { eq: "homePage/Tillster-min.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 400)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      tillsterMobile: file(relativePath: { eq: "homePage/Tillster-mobile.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 400)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      industry1: file(relativePath: { eq: "homePage/industries/1-financial.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200)  {
            ...GatsbyImageSharpFluid
          }
        }
      }
      industry2: file(relativePath: { eq: "homePage/industries/2-education.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 200)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      industry3: file(relativePath: { eq: "homePage/industries/3-ecommerce.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 200)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      industry4: file(relativePath: { eq: "homePage/industries/4-events.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 200)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      industry5: file(relativePath: { eq: "homePage/industries/5-open-source.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 200)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      industry6: file(relativePath: { eq: "homePage/industries/6-healthcare.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 200)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      industry7: file(relativePath: { eq: "homePage/industries/7-real-estate.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 200)  {
             ...GatsbyImageSharpFluid
          }
        }
      }
      homeImage: file(relativePath: { eq: "homePage/home-illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 800)  {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeImageTablet: file(relativePath: { eq: "homePage/home-illustration-tablet.png" }) {
         childImageSharp {
           fluid(maxWidth: 700)  {
            ...GatsbyImageSharpFluid
           }
         }
      }
      site {
        siteMetadata {
          title
          description
        }
      }
      allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
        edges {
          node {
            id
            timeToRead
            frontmatter {
              title
              showOnHome
              date(formatString: "MMM DD, YYYY")
              tags
              path
              mainImage {
                absolutePath
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              mobileImage {
                absolutePath
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
`;
