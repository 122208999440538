import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Button from './Button';

const ProjectCard = ({ docsLink, docsBtnName, internalLink, title, content, link, btnName, image, secondBtn,
  secondLink, toServicesPage }) => {
  const docsLinkToUse = docsLink ? <Button goTo={docsLink} title={docsBtnName} type="primary-dark-blank" /> : null;
  const goTo = toServicesPage ? `/services/#${toServicesPage}` : link;
  const internalLinkToUse = internalLink ? (
    <Button goTo={internalLink} title="demo" type="primary-dark" />) : null;

  return (
    <React.Fragment>
      { toServicesPage ? (
        <div className="project-card">
          <Link to={goTo} className={`project-card-${title.split(' ')[0]}`} tabIndex="-1">
            <img className="project-card__image" src={image} alt={title} title={title} width="45" height="45" />
            <h2 className="project-card__title" tabIndex="0">{title}</h2>
            <div
              className="project-card__content"
              tabIndex="0"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="project-card__buttons">
              <div
                className="project-card__buttons-link"
                tabIndex="0"
                onKeyDown={(event) => {
                  if (event.keyCode === 13) {
                    document.querySelector(`.project-card-${title.split(' ')[0]}`).click();
                  }
                }}
              >
                <i className="fa fa-github" />
                {btnName}
              </div>
              <div className="job__details--icon icon fa-arrow-right" />
              {docsLinkToUse}
              {internalLinkToUse}
            </div>
          </Link>
        </div>
      )
        : (
          <div className="project-card">
            <img className="project-card__image" src={image} alt={title} title={title} />
            <h2 className="project-card__title" tabIndex="0">{title}</h2>
            <div
              className="project-card__content"
              tabIndex="0"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <div className="project-card__buttons">
              {
              secondBtn
                ? (
                  <div className="project-card__second-button">
                    <a className="project-card__buttons-link-2" href={secondLink} rel="noopener noreferrer">
                      <i className="fa fa-external-link" />
                      {secondBtn}
                      <div className="job__details--icon icon fa-arrow-right" />
                    </a>
                    <a className="project-card__buttons-link" href={link} rel="noopener noreferrer">
                      <i className="fa fa-github" />
                      {btnName}
                      <div className="job__details--icon icon fa-arrow-right" />
                    </a>
                    {docsLinkToUse}
                    {internalLinkToUse}
                  </div>
                )
                : (
                  <div className="project-card__simple">
                    <a className="project-card__buttons-link" href={link} rel="noopener noreferrer">
                      <i className="fa fa-github" />
                      {btnName}
                      <div className="job__details--icon icon fa-arrow-right" />
                    </a>
                    {docsLinkToUse}
                    {internalLinkToUse}
                  </div>
                )
          }
            </div>
          </div>
        )}
    </React.Fragment>
  );
};

ProjectCard.propTypes = {
  docsLink: PropTypes.string,
  docsBtnName: PropTypes.string,
  internalLink: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  link: PropTypes.string,
  toServicesPage: PropTypes.string,
  btnName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

ProjectCard.defaultProps = {
  docsLink: '',
  docsBtnName: 'View Docs',
  internalLink: '',
  link: '',
  toServicesPage: '',
};

export default ProjectCard;
