import React, { Component } from 'react';

import NewQuoteForm from './NewQuoteForm';


export default class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { title, subtitle, page } = this.props;

    return (
      <div className="card main__inner main">
        <div className="card-info">
          <div className="card-info-title font-display" tabIndex="0">
            {title}
            <br />
            {subtitle}
            <span role="img" aria-label="hand">&#128075;</span>
          </div>
          { page === 'contact' ? (
            <div className="card-info-details">
              <div className="card-info-email" onClick={this.openEmailClient}>
                <span className="card-info-email-name" tabIndex="0">Email</span>
                <h4 className="card-info-email-contact" tabIndex="0">contact@mcro.tech</h4>
              </div>
              <div className="card-info-phone">
                <span className="card-info-phone-name" tabIndex="0">Phone</span>
                <h4 className="card-info-phone-number font-title2">
                  <a href="tel:+40 747 215 726">+40 747 215 726</a>
                </h4>
              </div>
              <div className="card-info-schedule">
                <a
                  href="https://calendly.com/alex-lazar/mcro-in-30min?month=2020-09"
                  className="font-weight-light-bold"
                >
                  Schedule a call with us
                </a>
              </div>
            </div>
          )
            : (
              <div className="card-info-details">
                <div className="card-info-description" tabIndex="0">
                  If you’re looking to transform your business and take your ideas from concept to launch,
                  we’re happy to help.
                </div>
                <div className="card-info-description" tabIndex="0">
                  Fill out the following form or drop us a line at
                  {' '}
                  <span className="card-info-description-email">
                    contact@mcro.tech.
                  </span>
                </div>
              </div>
            )}
        </div>

        <div className="card-form">
          {/* <div className="card-form-title font-big">LET'S TALK</div> */}
          <div className="contact-form">
            <NewQuoteForm />
          </div>
        </div>
      </div>
    );
  }
}
