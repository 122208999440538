import WebDev from '../assets/images/svg/services-web.svg';
import MobileDev from '../assets/images/svg/services-mobile.svg';
import ProductStrategy from '../assets/images/svg/services-ideation.svg';
import CloudInfrastructure from '../assets/images/svg/services-cloud.svg';
import ourWorkData from './our-work-data';

export const WhatWeAreGoodAt = [{
  image: WebDev,
  title: 'Web Development',
  description: 'We build modern, scalable web applications that are custom tailored to your needs and growth goals.',
  link: 'Web Development'
}, {
  image: MobileDev,
  title: 'Mobile Development',
  description: 'We build state-of-the-art iOS and Android apps for a variety of industries and business domains.',
  link: 'App Development'
}, {
  image: ProductStrategy,
  title: 'Product Strategy & UI/UX',
  description: 'We help you build digital products that delight your users as well as add value to your business. ',
  link: 'Ideation & Product Strategy'
}, {
  image: CloudInfrastructure,
  title: 'Cloud Infrastructure',
  description: 'We help you optimize and scale your business at any stage of your cloud journey.',
  link: 'Cloud Infrastructure'
}];

export const OurWork = [{
  title: 'Sigora Solar',
  description: 'A web and mobile platform built to improve the process of solar panel design and distribution',
  link: '/our-work/sigora-solar/'
}, {
  title: 'Conference Compass',
  description: 'Custom software solutions for virtual, hybrid or live events.',
  link: '/our-work/conference-compass/'
}, {
  title: 'Tillster Delivery',
  description: 'A custom-built online ordering platform to streamline food delivery orders.',
  link: '/our-work/kiosk-tillster-platform/'
}];

export const Testimonials = ourWorkData.filter(data => data.testimonial).map(data => data.testimonial);

export const Industries = [{
  title: 'Financial',
  image: require('../assets/images/homePage/industries/1-financial.jpg')
}, {
  title: 'Education',
  image: require('../assets/images/homePage/industries/2-education.jpg')
}, {
  title: 'Ecommerce',
  image: require('../assets/images/homePage/industries/3-ecommerce.jpg')
}, {
  title: 'Events',
  image: require('../assets/images/homePage/industries/4-events.jpg')
}, {
  title: 'Open Source',
  image: require('../assets/images/homePage/industries/5-open-source.jpg')
}, {
  title: 'Healthcare',
  image: require('../assets/images/homePage/industries/6-healthcare.jpg')
}, {
  title: 'Real Estate',
  image: require('../assets/images/homePage/industries/7-real-estate.jpg')
}];
